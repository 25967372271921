import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { ContentfulComponentMap } from '@components/utils';
import { ComponentLayoutProps } from '@ts/index';
import { useHasMounted, useIsMobile } from '@utils/hooks';
import { EXTRA_LARGE_WIDTH, MEDIUM_MAX_WIDTH } from '@constants';
import styles from './ComponentLayout.module.scss';

const Img = dynamic(() => import('@components/common/Img/Img'));

/**
 * Layout
 *
 * Corresponds to Contentful content type 'componentLayout'. Used as a wrapper for specific components
 * to create a row and column layout using CSS Grid.
 *
 * Resource: https://app.contentful.com/spaces/a8mjl2rcjwa7/content_types/componentLayout/fields
 *
 */
function ComponentLayout({
	internalName,
	rows,
	columns,
	marginBlock,
	componentBlocks,
	backgroundColor,
	backgroundImage,
	maxWidth = 'Auto',
	numberOfItemsMobile = null,
	numberOfItemsTablet = null,
	justifyContent = null,
	alignItems = null,
	ignorePadding = false,
}: ComponentLayoutProps) {
	const hasMounted = useHasMounted();
	const isMaxMobile = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });
	const isMaxTablet = useIsMobile({ maxWidth: EXTRA_LARGE_WIDTH });

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const componentsInsideLayout = useMemo(() => {
		const blocks = [...componentBlocks];

		if (hasMounted) {
			if (isMaxTablet && numberOfItemsTablet !== null && numberOfItemsTablet >= 0) blocks.length = numberOfItemsTablet;
			if (isMaxMobile && numberOfItemsMobile !== null && numberOfItemsMobile >= 0) blocks.length = numberOfItemsMobile;
		}

		return <ContentfulComponentMap blocks={blocks} />;
	}, [isMaxMobile, isMaxTablet, componentBlocks, numberOfItemsMobile, numberOfItemsTablet, hasMounted]);

	const classes = cn(
		styles['container'],
		styles[`max-width-${maxWidth.toLowerCase()}`],
		{
			[styles['mb-1']]: marginBlock === 'X-Small',
			[styles['mb-2']]: marginBlock === 'Small',
			[styles['mb-3']]: marginBlock === 'Medium',
			[styles['mb-4']]: marginBlock === 'Large',
			[styles['mb-5']]: marginBlock === 'X-Large',
			[styles['ignore-padding']]: ignorePadding,
		}
	);

	return (
		<div
			className={classes}
			style={{
				...(backgroundColor ? { backgroundColor } : {}),
				...(justifyContent ? { justifyContent: justifyContent.toLowerCase().replace(' ', '-') } : {}),
				...(alignItems ? { alignItems: alignItems.toLowerCase().replace(' ', '-') } : {}),
			}}
			data-component-name={internalName}
			data-columns={columns}
			data-has-more-one-row={rows > 1}
		>
			{backgroundImage ? (
				<>
					<Img
						className={styles['background-image']}
						src={backgroundImage.url}
						alt={backgroundImage.description ?? backgroundImage.title}
						height={256}
						noSrcset
					/>
					<div className={styles['stack-wrapper']}>
						{componentsInsideLayout}
					</div>
				</>
			) : componentsInsideLayout}
		</div>
	);
}

export default ComponentLayout;
